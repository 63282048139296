.text-link {
    background-color: transparent;
    color: $theme-color-2;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.text-color-unset {
    color: unset;
}