//
// projects.scss
//

// project list

.project-list-table {
  border-collapse: separate;
  border-spacing: 0 12px;
  tr {
    background-color: $card-bg;
  }
}

.col-with-card-same-height {
  padding-bottom: 24px;
  & > .card {
    height: 100%;
  }
}

.grid-cards {
    display: grid;
    width: 100%;
    grid-auto-flow: row;
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 24px;
}

.btn-disabled {
  pointer-events: none;
  background-color: #9cc31c80 !important;
  border-color: #9cc31c80 !important;
  &:hover {
    color: #fff !important;
    cursor: default !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.table-link {
  font-weight: 500;
}
.projects-container {
  h4,
  th,
  td,
  a,
  p,
  .card-title,
  label {
    color: $theme-color;
  }
  table {
    th {
      background-color: $gray-400;
      border: 0;
    }
    td {
      border: 0;
      &.isNotNecessary {
        @media screen and (min-width: 1351px) {
          display: table-cell;
        }
        @media screen and (max-width: 1350px) {
          display: none;
        }
      }
    }
    td:last-child {
      width: 100px;
    }
  }
  .table-link {
    font-weight: 500;
  }
  button {
    background-color: #9cc31c !important;
    color: #fff !important;
    font-weight: 500 !important;
    border-color: transparent !important;

    &:hover {
      background-color: #89aa1d !important;
      color: #fff !important;
      font-weight: 500 !important;
      border-color: transparent !important;
    }

    &:active {
      background-color: #89aa1d !important;
      color: #fff !important;
      font-weight: 500 !important;
      border-color: transparent !important;
    }

    &:focus {
      background-color: #89aa1d !important;
      color: #fff !important;
      font-weight: 500 !important;
      border-color: transparent !important;
    }
  }
  .btn-group-filter {
    flex-wrap: wrap;
    & button.button-filter {
      color: $theme-color-2;
      font-weight: 500;
      border-color: $theme-color-2;
      border-radius: 0;
      margin: 0;
      &:hover,
      &.active {
        background-color: $theme-color-2;
        color: $white;
        border-color: transparent;
        z-index: 0;
      }
    }
  }
  .visite-table-container table td {
    width: 8%;
    &:nth-child(9) {
      width: 20%;
    }
  }
  .prime-table-container table td {
    width: calc(100% / 9);
  }
  .deposit-table-container table td {
    width: 10%;
  }
  .administratif-table-container table td {
    width: 12.5%;
  }
  .react-bootstrap-table-pagination {
    button {
      background-color: $theme-color;
      color: $white;
      font-weight: 500;
      border-color: transparent;
      &:hover {
        background-color: $theme-color;
        color: $btn-color-hover;
        border-color: transparent;
      }
    }
    .page-item {
      border-radius: 50%;
      display: flex;
      height: 38px;
      width: 38px;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
      a {
        color: $theme-color;
        border-radius: 50%;
        display: flex;
        height: 38px;
        width: 38px;
        min-width: 38px;
        align-items: center;
        justify-content: center;
        border-color: transparent;
        padding: 0;
      }
      &:focus {
        box-shadow: unset;
      }
      &.active {
        background-color: $theme-color;
        border-color: $theme-color;
      }
      &.active a {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
      & a:hover {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
    }
  }
}
.project-disponibility-info {
  border-top: 1px solid #eff2f7;
  margin: 0;
  padding: 8px;
  &:last-child {
    border-bottom: 1px solid #eff2f7;
  }
  &:hover {
    background-color: #fafafa;
  }
  & i.fas {
    cursor: pointer;
    color: $redColor;
    &:hover {
      color: darken($redColor, 10%);
    }
  }
}
.disponibility-new,
.fourniture-fees-new {
  margin-top: 1rem;
  & .disponibility-datepickers {
    display: flex;
    & .disponibility-datepicker-container {
      flex: 1 1 33%;
      margin: 0 2px 4px;
    }
  }
  & .disponibility-add-input,
  & .fourniture-fees-add-input {
    & span {
      margin-left: 2px;
      padding: 8px 12px;
      display: inline-block;
      border-radius: 4px;
      border: 1px solid #ced4da;
      color: #495057;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
  & .fourniture-fees-add-input {
    margin-bottom: 4px;
  }
}
.fourniture-fees-new-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fourniture-fees-new-item {
    flex: 1 1 50%;
    margin: 0 2px 4px;
  }
}
.fourniture-fees-info {
  border-top: 1px solid #eff2f7;
  margin: 0;
  padding: 8px;
  &:last-child {
    border-bottom: 1px solid #eff2f7;
  }
  &:hover {
    background-color: #fafafa;
  }
  & i.fas {
    cursor: pointer;
    &.fa-check {
      color: $theme-color-2;
      &:hover {
        color: darken($theme-color-2, 10%);
      }
    }
    &.fa-pencil-alt {
      color: #495057;
      &:hover {
        color: darken(#495057, 10%);
      }
    }
    &.fa-times {
      color: $redColor;
      &:hover {
        color: darken($redColor, 10%);
      }
    }
  }
  .fourniture-fees-info-buttons {
    display: flex;
    padding-left: 8px;
  }
}
.technical-visite-header {
  & i.fas {
    cursor: pointer;
    color: $redColor;
  }
}
.technical-visite-container {
  & .technical-visite-date {
    display: flex;
  }
  .technical-visite-label {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  .technical-visite-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & div {
      text-align: center;
      & i.fas {
        cursor: pointer;
        &.fa-check {
          color: $theme-color-2;
          padding-bottom: 12px;
        }
        &.fa-times {
          color: $redColor;
        }
      }
    }
  }
}
.cee-prime-container,
.administrative-folder-container,
.deposit-container,
.financement-container {
  .cee-prime-row,
  .administrative-folder-row,
  .deposit-row,
  .financement-row {
    margin-bottom: 1rem;
    .cee-prime-item:not(.cee-prime-item-block),
    .administrative-folder-item,
    .deposit-item:not(.deposit-item-block),
    .financement-item {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        flex: 1 1 33%;
      }
      & > div:not(.react-datepicker__tab-loop),
      & > span {
        flex: 1 1 67%;
      }
      & > div.react-datepicker__tab-loop {
        flex: 0;
      }
    }
    .cee-prime-item-block,
    .deposit-item-block {
      p {
        margin-bottom: 8px;
      }
    }
  }
}
.financement-info-container {
  margin-top: 16px;
}
.project-detail-date-container {
  display: flex;
  align-items: center;
  & > span {
    flex: 1 1 18%;
  }
}

.projects-actions {
  background: none;
  box-shadow: none;
}