@import "../../variables";
//
// _helper.scss
//

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

// Font weight help class

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 1px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;
  &:hover{
    color: $gray-600;
    background-color: $gray-200;
  }
}


.w-xs {
  width: 80px;
}
.w-sm {
  width: 95px;
}
.w-md {
  width: 110px;
}
.w-lg {
  width: 140px;
}
.w-xl {
  width: 160px;
}
.w-2xl {
  width: 200px;
}
.w-3xl {
  width: 250px;
}
.w-4xl {
  width: 300px;
}


.h1-font-size{
  font-size: $h1-font-size;
}
.h2-font-size{
  font-size: $h2-font-size;
}
.h3-font-size{
  font-size: $h3-font-size;
}
.h4-font-size{
  font-size: $h4-font-size;
}
.h5-font-size{
  font-size: $h5-font-size;
}
.h6-font-size{
  font-size: $h6-font-size;
}
.font-size-lg{
  font-size: $font-size-lg;
}
.font-size-md{
  font-size: $font-size-lg;
}
.font-size-sm{
  font-size: $font-size-sm;
}
.font-size-base{
  font-size: $font-size-base;
}

$colors-map:(
  "theme-color":$theme-color,
  "theme-color-2":$theme-color-2,
  "gray-300":$gray-300,
  "blue":$blue,
);

@each $i1, $i2 in ("text": "color", "bg":"background-color"){
  @each $k, $c in $colors-map{
    .#{$i1}-#{$k}{
      #{$i2}:$c;
    }
    .\!#{$i1}-#{$k}{
      #{$i2}:$c !important;
    }
    .hover\:#{$i1}-#{$k}:hover{
      #{$i2}:$c;
    }
  }
}