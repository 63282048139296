//
// Forms.scss
//

// checkbox input right
.form-control{
  min-height: 38px;
}

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.25rem;
  .form-check-input {
    right: 0;
    margin-left: 0;
  }

  .form-check-label {
    display: block;
  }
}

.custom-control-right {
  padding-left: 0;
  padding-right: 1.5rem;
  display: inline-block;
  .custom-control-label {
    display: inline-block;
    &:before,
    &:after {
      left: auto;
      right: -1.5rem;
    }
  }

  .custom-control-input {
    left: auto;
  }
}

.form-checkbox {
  width: 1.6em; // calc( 1.5em + 0.94rem + 2px) = form-control height
  position: unset;
  margin-top: unset;
  margin-left: unset;
}

.form-color {
  width: 60px;
  height: 60px;
  padding: 0;
}

.disabled {
  background-color: hsl(0, 0%, 95%) !important;
  border-color: hsl(0, 0%, 95%) !important;
}
.custom-file-label{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 100px;
  z-index: 0;
  cursor:pointer;
  &:after{
    content:"Parcourir"
  }
}