.map-container {
  .projects-list-title {
    font-size: 1.01562rem;
    & span {
      font-weight: 600;
    }
  }
  .projects-list {
    height: 100%;
    overflow-y: scroll;
    .project-info-container {
      justify-content: space-between;
      border-bottom: 1px solid #e8eaed;
      padding: 15px 12px;
      cursor: pointer;
      &:first-child {
        border-top: 1px solid #e8eaed;
      }
      &:hover {
        background-color: #fafafa;
      }
      & p {
        margin: 0;
      }
      .project-info {
        font-size: 14px;
        color: #08233b;
        &-small {
          font-size: 13px;
          color: #6c757d;
        }
      }
      .project-info-disponibilities {
        & > div:first-child {
          min-width: 120px;
          width: 120px;
        }
        & > div:last-child {
          min-width: 150px;
          width: 150px;
        }
      }
      .project-info-disponibility_vt {
        & > div:first-child {
          min-width: 150px;
          width: 150px;
        }
        & > div:last-child {
          min-width: 150px;
          width: 150px;
        }
      }
    }
  }
  .info-box-container {
    background-color: #fff;
    padding: 15px 12px;
    margin: 4px 5px 10px 5px;
    width: 265px;
    box-shadow: 0 4px 10px rgba(60, 64, 67, 0.28);
    border-radius: 8px;
    .info-box {
      font-size: 14px;
      color: #08233b;
      &-small {
        font-size: 13px;
        color: #6c757d;
      }
    }
  }
}
.disponibility-vt-modal-container {
  & .disponibility-vt-flex-container {
    display: flex;
    & .disponibility-vt-flex-item {
      flex: 1 1 50%;
      margin: 0 3px;
    }
  }
  & p {
    margin-bottom: 0.5rem;
  }
  & .disponibility-vt-input {
    margin-bottom: 1rem;
  }
}
.white-space-pre-line {
  white-space: pre-line;
}
.map-modal-list-items {
  overflow-x: scroll;
  max-height: 420px;
}

.custom-map-buttons {
  display: flex;
  background: none;
  padding: 3px 3px;
  margin: 0 10px;
  height: min-content;
  width: max-content;
}

.custom-map-buttons svg {
  width: 80%;
  margin: auto;
}

.custom-map-buttons:hover path {
  fill: white;
}

.add-button-map {
  border: solid 2px $theme-color-2;
}

.add-button-map:hover {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
}


.arrow-button-map {
  border: solid 2px $theme-color;
}

.arrow-button-map:hover {
  background-color: $theme-color;
  border-color: $theme-color;
}

.delete-button-map {
  border: solid 2px $danger;
}

.delete-button-map:hover {
  background-color: $danger;
  border-color: $danger;
}

.buttons-waypoint {
  display: flex;
}