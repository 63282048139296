.btn-icon-delete {
  color: red;
  cursor: pointer;
}

.btn-icon-add {
  color: #9cc31c;
  cursor: pointer;
}

.custom-control-with-margin {
  margin-left: 12px;
}

.quotes-container {
  h4,
  th,
  td,
  a,
  p,
  .card-title,
  label {
    color: $theme-color;
  }
  .table-link {
    font-weight: 500;
  }
  .table-text-black {
    color: $black;
  }
  .table-text-important {
    color: $danger;
  }
  .text-theme-color-2 {
    color: $theme-color-2;
  }
  table:not(.table-order-free-items-container) {
    th {
      background-color: $gray-400;
      border: 0;
      &:first-child {
        width: 60px;
      }
      &:last-child {
        width: 100px;
      }
      &.isNotNecessary {
        @media screen and (min-width: 1351px) {
          display: table-cell;
        }
        @media screen and (max-width: 1350px) {
          display: none;
        }
      }
    }
    td {
      border: 0;
      &.isNotNecessary {
        @media screen and (min-width: 1351px) {
          display: table-cell;
        }
        @media screen and (max-width: 1350px) {
          display: none;
        }
      }
      &:first-child {
        width: 60px;
      }
      &:last-child {
        width: 100px;
      }
    }
  }
  button:not(.button-set-danger):not(.button-filter):not(.react-datepicker__navigation):not(.close) {
    background-color: $theme-color-2;
    color: $white;
    font-weight: 500;
    border-color: transparent;
    &:hover {
      background-color: $theme-color-2;
      color: $btn-color-hover;
      border-color: transparent;
    }
  }
  .btn-group-filter {
    flex-wrap: wrap;
    & button.button-filter {
      color: $theme-color-2;
      font-weight: 500;
      border-color: $theme-color-2;
      border-radius: 0;
      margin: 0;
      &:hover,
      &.active {
        background-color: $theme-color-2;
        color: $white;
        border-color: transparent;
        z-index: 0;
      }
    }
  }
  .react-datepicker button {
    background-color: unset;
    color: unset;
    font-weight: unset;
    border-color: transparent;
    &:hover {
      background-color: unset;
      color: unset;
      border-color: transparent;
    }
    &.react-datepicker__navigation--next {
      border-left-color: #ccc;
    }
    &.react-datepicker__navigation--previous {
      border-right-color: #ccc;
    }
  }
  .react-bootstrap-table-pagination {
    button {
      background-color: $theme-color;
      color: $white;
      font-weight: 500;
      border-color: transparent;
      &:hover {
        background-color: $theme-color;
        color: $btn-color-hover;
        border-color: transparent;
      }
    }
    .page-item {
      border-radius: 50%;
      display: flex;
      height: 38px;
      width: 38px;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
      a {
        color: $theme-color;
        border-radius: 50%;
        display: flex;
        height: 38px;
        width: 38px;
        min-width: 38px;
        align-items: center;
        justify-content: center;
        border-color: transparent;
        padding: 0;
      }
      &:focus {
        box-shadow: unset;
      }
      &.active {
        background-color: $theme-color;
        border-color: $theme-color;
      }
      &.active a {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
      & a:hover {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
    }
  }
  .container-text-theme-color-2 {
    p,
    a {
      color: $theme-color-2;
    }
  }
  .collaborators-footer-img {
    margin-top: -200px;
    position: relative;
    z-index: -1;
    .collaborators-footer-img-main-container {
      position: relative;
      .collaborators-footer-img-container:nth-child(2) {
        position: absolute;
        top: 100px;
        left: 20%;
        width: 43%;
        img {
          width: 100%;
        }
      }
      .collaborators-footer-img-container:nth-child(1) img {
        width: 55%;
      }
    }
  }
  .sub-form-container {
    border: 1px solid $gray-400;
    border-radius: 4px;
    padding: 20px;
  }
  .table-td-button {
    display: inline-block;
    width: 120px;
  }
  .container-filter-datepicker {
    max-width: 544px;
    margin: auto;
    & .item-filter-datepicker {
      width: 250px;
      text-align: left;
    }
  }
}


/* ###### Form style ##### */

/* radio to checkbox */
div.custom-checkbox{
  position: relative;
}
div.custom-checkbox input[type=radio]{
  opacity: 0;
  position: absolute;
}
div.custom-checkbox label{
  padding-left: 1.5rem;
}
div.custom-checkbox input[type=radio] + label:after{
  content: "✓";
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 30%;
  left: 5px;
  transform: translateY(-50%);
  border: 1px solid grey;
  box-sizing: border-box;
  background-color: white;
  border-radius: 3px;
  transition: background-color .3s;
}
div.custom-checkbox input[type=radio]:checked + label:after {
  background-color: grey;
}

.items-container {
  display: flex;
  flex-direction: row;
  column-gap: 5vw;
}

/* free fields form depending of item adding */
.free-field-form {
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.form-input {
  display: flex;
}

.totalPricesTable {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 15px;
}

.quote-form-buttons {
  column-gap: 15px;
}