//
// Extras pages.scss
//

// pricing

.color-green {
  color: $theme-color-2 !important;
}
.btn-green {
  color: white;
  background-color: $theme-color-2;
  border-color: $theme-color-2;
}
.plan-box {
  .plan-btn {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: $gray-300;
      left: 0px;
      right: 0px;
      top: 12px;
    }
  }
}
