.subscriptions-detail span {
  font-weight: 500;
}

.subscriptions-detail > div.row {
  margin-bottom: 1em;
}

.preview-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-head th {
  text-align: center;
}

.preview-head.badge {
  width: 100%;
  background-color: #9cc31c;
}

.preview-body {
  border-spacing: 15px;
  border: solid 1px lightgray;
}

.preview-modal {
  display: flex;
  justify-content: center;
  width: 60vw !important;
  max-width: inherit !important;
}

table {
  width: 100%;
}

.bx-x::before, .bx-download::before{
  font-size: large;
}

a.disabled-link {
  pointer-events: none;
  cursor: default;
}