.btn-no-background {
    background: none !important;
    border: none !important;
}

.btn-freefield-edit {
    color: $black !important;
    font-weight: bold !important;
}

.btn-freefield-delete {
    color: $red !important;
    font-weight: bold !important;
}

.btn-freefield-order {
    color: $black !important;
    font-weight: bold !important;
    font-size: 1.2em;
}