.notifications-container {
  h4,
  h5,
  th,
  td,
  a,
  p,
  .card-title,
  label {
    color: $theme-color;
  }
  .table-link {
    font-weight: 500;
  }
  .table-text-black {
    color: $black;
  }
  .table-text-important {
    color: $danger;
  }
  .text-theme-color-2 {
    color: $theme-color-2;
  }
  table {
    th {
      background-color: $gray-400;
      border: 0;
      width: calc(calc(100% - 60px) / 6);
      &:last-child {
        width: 60px !important;
      }
    }
    &.light-header th{
      background-color: $gray-200;
    }
    td {
      border: 0;
      width: calc(calc(100% - 60px) / 6);
      &:last-child {
        width: 60px !important;
      }
    }
  }
  .notifications-list-actions-button {
    cursor: pointer;
  }
  button:not(.button-set-danger):not(.button-filter):not(.react-datepicker__navigation):not(.close):not(.dropdown-item) {
    background-color: $theme-color-2;
    color: $white;
    font-weight: 500;
    border-color: transparent;
    &:hover {
      background-color: $theme-color-2;
      color: $btn-color-hover;
      border-color: transparent;
    }
  }
  .react-datepicker button {
    background-color: unset;
    color: unset;
    font-weight: unset;
    border-color: transparent;
    &:hover {
      background-color: unset;
      color: unset;
      border-color: transparent;
    }
    &.react-datepicker__navigation--next {
      border-left-color: #ccc;
    }
    &.react-datepicker__navigation--previous {
      border-right-color: #ccc;
    }
  }
  .react-bootstrap-table-pagination {
    button {
      background-color: $theme-color;
      color: $white;
      font-weight: 500;
      border-color: transparent;
      &:hover {
        background-color: $theme-color;
        color: $btn-color-hover;
        border-color: transparent;
      }
    }
    .page-item {
      border-radius: 50%;
      display: flex;
      height: 38px;
      width: 38px;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
      a {
        color: $theme-color;
        border-radius: 50%;
        display: flex;
        height: 38px;
        width: 38px;
        min-width: 38px;
        align-items: center;
        justify-content: center;
        border-color: transparent;
        padding: 0;
      }
      &:focus {
        box-shadow: unset;
      }
      &.active {
        background-color: $theme-color;
        border-color: $theme-color;
      }
      &.active a {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
      & a:hover {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
    }
  }
}
.notification-detail-title {
  display: inline-block;
  width: 35%;
  font-weight: 500;
}
.messages-list-header {
  display: flex;
  margin: 15px 0;
  & h5 {
    margin-bottom: 0;
  }
  &-link {
    margin-bottom: 0;
    & span {
      margin-left: 12px;
    }
    &-content {
      cursor: pointer;
      &:hover {
        color: $theme-color-2;
      }
    }
  }
}
.messages-list {
  & .message-container {
    margin-left: 20px;
    margin-right: 20px;
    & .message {
      position: relative;
      margin-top: 15px;
      border: 1px solid $gray-400;
      border-radius: 3px;
      & .message-header {
        display: flex;
        justify-content: space-between;
        padding: 12px 15px;
        border-bottom: 1px solid $gray-400;
        background-color: #fafafa;
        & span {
          font-weight: 500;
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 15px;
          right: 100%;
          left: -14px;
          width: 0;
          height: 0;
          border-style: solid solid outset;
          border-color: transparent;
          pointer-events: none;
          border-width: 7px;
          border-right-color: #d1d3e0;
        }
      }
      & .message-content {
        padding: 12px 15px;
        &.message-content-border-bottom {
          border-bottom: 1px solid $gray-400;
        }
        ul {
          margin-bottom: 0;
          padding-left: 18px;
          li {
            margin-bottom: 5px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        span {
          font-weight: 500;
        }
      }
      & .message-text {
        padding: 15px;
        & p {
          margin: 0;
        }
      }
    }
  }
}
.message-form {
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;
  padding: 15px 0 25px;
  margin: 10px 20px 15px;
}
