.paymentForm {

  .form-group {
    width: 100%;
  }

  .actions {
    justify-content: flex-end;
    margin: 0 10px;
    gap: 10px
  }
}

.payments-table {

  .actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  .actions > a {
    display: flex;
    align-items: center;
  }
}