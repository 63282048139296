.calendar-modal-header {
    div {
        width: 100%;
    }

    .custom-header-button {

        &:focus {
            border: none;
            box-shadow: none;
        }

        border: none;
        padding: 0;
        color: #74788d !important;
        background: none !important;
        position: relative;
        top: -5px;

        &.danger {
            color: #f46a6a !important;
        }
    }

    button {
        font-size: 1.8em;
    }
}

.calendar-modal-body {

    .color-box {
        height: 20px;
        width: 20px;
        border-radius: 10%;
    }
}