.bg-brown {
  background-color: $event-brown;

  &:hover {
    background-color: $event-brown-hover;
  }
}

.bg-pink {
  background-color: $event-pink;

  &:hover {
    background-color: $event-pink-hover;
  }
}

.bg-green {
  background-color: $event-green;

  &:hover {
    background-color: $event-green-hover;
  }
}

.bg-blue {
  background-color: $event-blue;

  &:hover {
    background-color: $event-blue-hover;
  }
}

.bg-orange {
  background-color: $event-orange;

  &:hover {
    background-color: $event-orange-hover;
  }
}

.calendar-caption-circle {
  display: inline-block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  &.border-brown {
    border-color: $event-brown;
  }
  &.border-pink {
    border-color: $event-pink;
  }
  &.border-green {
    border-color: $event-green;
  }
  &.border-blue {
    border-color: $event-blue;
  }
  &.border-orange {
    border-color: $event-orange;
  }
}

.calendar-container {
  .modal-dialog {
    label {
      font-size: 0.75rem;
    }
  }
  .fc-event-container {
    .fc-event {
      padding: 4px 4px;
      font-size: 0.65rem;
      margin-top: 2px;
      margin-bottom: 2px;
      text-align: left;
    }
  }
  .calendar-filter-select {
    z-index: 2;
    &.filter-select-first-line {
      z-index: 3;
    }
  }
  h4,
  th,
  td,
  a,
  p,
  .card-title,
  label {
    color: $theme-color;
  }
  .text-theme-color-2 {
    color: $theme-color-2;
  }
  button:not(.button-set-danger):not(.button-filter):not(.react-datepicker__navigation) {
    background-color: $theme-color-2;
    color: $white;
    font-weight: 500;
    border-color: transparent;
    &:hover {
      background-color: $theme-color-2;
      color: $btn-color-hover;
      border-color: transparent;
    }
  }
  .btn-group {
    flex-wrap: wrap;
    & button {
      color: $theme-color-2;
      font-weight: 500;
      border-color: $theme-color-2;
      margin: 0;
      &:hover,
      &.active {
        background-color: $theme-color-2;
        color: $white;
        border-color: transparent;
        z-index: 0;
      }

      &.active {
        opacity: 0.65;
      }
    }
  }
  .button-without-style {
    .react-datepicker {
      display: inline-flex !important;
      button {
        background-color: unset;
        color: unset;
        font-weight: unset;
        border-color: transparent;
        &:hover {
          background-color: unset;
          color: unset;
          border-color: transparent;
        }
        &.react-datepicker__navigation--next {
          border-left-color: #ccc;
        }
        &.react-datepicker__navigation--previous {
          border-right-color: #ccc;
        }
      }
    }
  }
  .container-text-theme-color-2 {
    p,
    a {
      color: $theme-color-2;
    }
  }
}
.react-datepicker-popper-custom {
  min-width: 328px;
}

.fc-daygrid-event {
  padding: 0;

  .fc-vertical-content {
    white-space: initial !important;

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;

    * {
      color: white;
      overflow-wrap: break-word;
      text-align: left;
    }

    .title {
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
    }

    &.little-font-size {
      font-size: 0.85em;
      padding: 3px;

      .technicians {
        font-size: 0.9em;
      }
    }
  }
}

.info-bar {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: white;

  font-size: 1.5em;

  box-shadow: -1px 2px 3px #bbb;
  border-radius: 10% 0 0 10%;

  // Center icon
  display: flex;
  justify-content: center;
  align-items: center;
}
