.table-order-free-items-container {
  th {
    background-color: $gray-400;
    border: 0;
  }
  td {
    border: 0;
  }
  th,
  td {
    &:nth-child(1) {
      width: 36%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      width: 8%;
    }
  }
}

.datepicker-error {
  border-color: #f46a6a;
}
