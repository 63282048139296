//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.sortable > span {
  margin-left: 10px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.sortable {
  position: relative;
  padding-right: 1.5rem !important;
  .order, .react-bootstrap-table-sort-order {
    display: flex;
    flex-direction: column-reverse;
    gap: .15rem;
    position: absolute;
    top: 50%;
    right: .75rem;
    transform: translateY(-50%);
    & .dropdown,.dropdown,
    & .dropup,.dropup {
      font-size: 0.2rem;
      &.green {
        color: #89aa1d;
      }
    }
  }
}
