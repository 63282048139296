//
// _buttons.scss
//
@import "../../variables";
button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark,
.btn-secondary {
  color: $gray-200 !important;
}

.btn-outline-light {
  color: $gray-900;
}

.border-gray {
  border: 1px solid #ccc;
  color: hsl(0, 0%, 50%);
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
}

.border-left-dark-gray {
  border-left: 1px solid #ddd;
}

.btn.btn-green{
  background: $theme-color-2 !important;
  color: $gray-200 !important;
  &:hover{
    background: $event-green-hover !important;
  }
  &:disabled{
    color: $gray-100 !important;
    background: $gray-500 !important;
  }
  &.outline{
    background: transparent !important;
    color: $theme-color-2 !important;
    border: 2px solid $theme-color-2 !important;
    &:hover{
      background: $event-green-hover !important;
      border-color: $event-green-hover !important;
      color: $gray-200 !important;
    }
  }
}