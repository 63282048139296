.search-filter {
  background: #FFFFFF;
  border: 1px solid #C0BDBD;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 8px 16px 8px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6B6B6B;
  display: flex;
  pointer-events: none;

  input {
    border: none;
    outline: none;
    height: 100%;
    margin: 0;
    padding: 0;
    pointer-events: auto;
    padding-right: 16px;
    flex: 1 1 auto;
  }

  &:after {
    width: 20px;
    height: 20px;
    border-left: 1px solid #6B6B6B;
    content: '\ebbc';
    font-family: 'boxicons' !important;
    color: #6B6B6B;
    font-weight: normal;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    line-height: 1;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    pointer-events: auto;
    cursor: pointer;
    flex: 0 0 auto;
  }
  &.no-separator{
    &:after{
      border-left: none;
    }
  }
}