.badge-color-draft {
  background-color: $gray-600;
}
.badge-color-quoted {
  background-color: $gray-600;
}
.badge-color-financing_request {
  background-color: $orange;
}
.badge-color-refusal {
  background-color: $red;
}
.badge-color-pre_acceptance {
  background-color: $orange;
}
.badge-color-acceptance {
  background-color: $yellow;
}
.badge-color-pending_deposit {
  background-color: $orange;
}
.badge-color-pending_deposit_encasement {
  background-color: $orange;
}
.badge-color-deposit_receive {
  background-color: $yellow;
}
.badge-color-technical_visit {
  background-color: $yellow;
}
.badge-color-pending_amendment_signature {
  background-color: $yellow;
}
.badge-color-pending_subcontracting {
  background-color: $primary;
}
.badge-color-in_progress_subcontracting {
  background-color: $primary;
}
.badge-color-finished_subcontracting {
  background-color: $primary;
}
.badge-color-installation {
  background-color: $yellow;
}
.badge-color-pending_invoice {
  background-color: $yellow;
}
.badge-color-invoiced {
  background-color: $yellow;
}
.badge-color-late_payment {
  background-color: $yellow;
}
.badge-color-maintenance_contract_in_progress {
  background-color: $green;
}
.badge-color-finish {
  background-color: $green;
}

.badge-abonnement-color-in_progress {
  background-color: $green;
}

.badge-abonnement-color-waiting {
  background-color: $yellow;
}

.badge-abonnement-color-refuse {
  background-color: $red;
}

.badge-abonnement-color-without_contract {
  background-color: $gray-600;
}
