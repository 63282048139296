.accordion-title {
  cursor: pointer;
  margin: 0;
}

.accordion-error {
  color: red;
  background-color: rgba(255, 0, 0, 0.05);
  hr {
    border-color: red;
  }
}

.ratings {
  .fa-star.fas:not(.empty) {
    color: #e1b54c;
  }
  .fa-star.fas.empty {
    color: #d3ced2;
  }
}

.custom-control {
  z-index: 0 !important;
}

.custom-row {
  width: max-content;
}
