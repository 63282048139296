@import '../../variables.scss';

.cardHeader {
  color: #333;
  margin-bottom: 2rem;
}

.listImage {
  height: 160px;
  max-width: 100%;

  &:before {
    font-size: 160px;
  }
}

.listImageContainer {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 0 10px 10px 0;
  height: 200px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;

  .listImageDeleteIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    margin: 0;
    background-color: $redColorBackground;
    border-color: $redColorBackground;

    &:hover {
      background-color: $redColor;
      border-color: $redColor;
    }

    > i {
      font-size: 30px;
      padding: 2px 2px 0 2px;
    }
  }

  > a {
    text-align: center;
  }

  > p {
    margin: 5px 0 0 0;
    text-align: center;
    border-top: 1px solid #ced4da;
  }
}
