.login-header-bg-soft {
  background-color: $theme-color-3;
  .login-header-text-theme-color {
    font-weight: bold;
    color: $theme-color;
  }
}

.login-header-text-theme-color {
  font-weight: bold;
  color: $theme-color;
}

.login-header-img {
  max-width: 160px;
}

.login-container {
  button {
    background-color: $theme-color-2;
    color: $white;
    font-weight: 500;
    &:hover {
      color: $btn-color-hover;
    }
  }
  label,
  input,
  a,
  p {
    color: $black;
  }
  .text-theme-color-2 {
    color: $theme-color-2;
  }
}
