.selectorError {
  > div {
    border-color: $red;
  }
}

.errorMessages {
  display: block;
}

.collaborators-container {
  h4,
  th,
  td,
  a,
  p,
  .card-title,
  label {
    color: $theme-color;
  }
  .table-link {
    font-weight: 500;
  }
  .table-text-black {
    color: $black;
  }
  .table-text-important {
    color: $danger;
  }
  .text-theme-color-2 {
    color: $theme-color-2;
  }
  table:not(.table-order-free-items-container) {
    th {
      background-color: $gray-400;
      border: 0;
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        @media screen and (min-width: 1351px) {
          display: table-cell;
        }
        @media screen and (max-width: 1350px) {
          display: none;
        }
      }
    }
    td {
      border: 0;
      &.isNotNecessary {
        @media screen and (min-width: 1351px) {
          display: table-cell;
        }
        @media screen and (max-width: 1350px) {
          display: none;
        }
      }
    }
    td:first-child {
      width: 60px;
    }
    td:last-child {
      width: 100px;
    }
  }
  button:not(.button-set-danger):not(.button-filter):not(.react-datepicker__navigation):not(.close) {
    background-color: $theme-color-2;
    color: $white;
    font-weight: 500;
    border-color: transparent;
    &:hover {
      background-color: $theme-color-2;
      color: $btn-color-hover;
      border-color: transparent;
    }
  }
  .btn-group-filter {
    flex-wrap: wrap;
    & button.button-filter {
      color: $theme-color-2;
      font-weight: 500;
      border-color: $theme-color-2;
      border-radius: 0;
      margin: 0;
      &:hover,
      &.active {
        background-color: $theme-color-2;
        color: $white;
        border-color: transparent;
        z-index: 0;
      }
    }
  }
  .react-datepicker button {
    background-color: unset;
    color: unset;
    font-weight: unset;
    border-color: transparent;
    &:hover {
      background-color: unset;
      color: unset;
      border-color: transparent;
    }
    &.react-datepicker__navigation--next {
      border-left-color: #ccc;
    }
    &.react-datepicker__navigation--previous {
      border-right-color: #ccc;
    }
  }
  .react-bootstrap-table-pagination {
    button {
      background-color: $theme-color;
      color: $white;
      font-weight: 500;
      border-color: transparent;
      &:hover {
        background-color: $theme-color;
        color: $btn-color-hover;
        border-color: transparent;
      }
    }
    .page-item {
      border-radius: 50%;
      display: flex;
      height: 38px;
      width: 38px;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
      a {
        color: $theme-color;
        border-radius: 50%;
        display: flex;
        height: 38px;
        width: 38px;
        min-width: 38px;
        align-items: center;
        justify-content: center;
        border-color: transparent;
        padding: 0;
      }
      &:focus {
        box-shadow: unset;
      }
      &.active {
        background-color: $theme-color;
        border-color: $theme-color;
      }
      &.active a {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
      & a:hover {
        background-color: $theme-color;
        border-color: $theme-color;
        color: $white;
      }
    }
  }
  .container-text-theme-color-2 {
    p,
    a {
      color: $theme-color-2;
    }
  }
  .collaborators-footer-img {
    margin-top: -200px;
    position: relative;
    z-index: -1;
    .collaborators-footer-img-main-container {
      position: relative;
      .collaborators-footer-img-container:nth-child(2) {
        position: absolute;
        top: 100px;
        left: 20%;
        width: 43%;
        img {
          width: 100%;
        }
      }
      .collaborators-footer-img-container:nth-child(1) img {
        width: 55%;
      }
    }
  }
  .sub-form-container {
    border: 1px solid $gray-400;
    border-radius: 4px;
    padding: 20px;
  }
  .table-td-button {
    display: inline-block;
    width: 120px;
  }
  .container-filter-datepicker {
    max-width: 544px;
    margin: auto;
    & .item-filter-datepicker {
      width: 250px;
      text-align: left;
    }
  }
}
